import React from 'react';
import { bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import { useHistory, useLocation } from 'react-router-dom';
import SectionContainer from '../SectionContainer';
import css from './SectionHeroSearch.module.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import { SecondaryButton, PrimaryButton } from '../../../../components/Button/Button';

import { useRouteConfiguration } from '../../../../context/routeConfigurationContext';
import { createResourceLocatorString } from '../../../../util/routes';



// Section component for a website's hero section
// The Section Hero doesn't have any Blocks by default, all the configurations are made in the Section Hero settings
const SectionHeroSearch = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    title,
    description,
    appearance,
    callToAction,
    options,
  } = props;

  const routeConfiguration = useRouteConfiguration();
  const history = useHistory();


  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    const searchQuery = event.target.elements.searchInput.value;

    const topbarSearchParams = () => {
      return { keywords: searchQuery };
    };

    const searchParams = {
      ...topbarSearchParams(),
    };

    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams));

  };

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);
  return (
    <>
    <SectionContainer
      id={sectionId}
      className={className}
      rootClassName={classNames(rootClassName || css.root)}
      appearance={appearance}
      options={fieldOptions}
    >
      {hasHeaderFields ? (
        <header className={defaultClasses.sectionDetails}>
          <Field data={title} className={defaultClasses.title} options={fieldOptions} />
          <Field data={description} className={defaultClasses.description} options={fieldOptions} />
          <div className={css.searchBox}>
            <form onSubmit={handleSubmit} style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                <input 
                  type="text" 
                  name="searchInput"
                  className={css.searchInput} 
                  placeholder="Discover Items for Rent" 
                />
                <button type="submit" className={css.searchButton}>
                  <FontAwesomeIcon icon={faSearch} className={css.faSearchIcon} />
                </button>
              </form>
          </div>
        </header>
       
      ) : null}
      
    </SectionContainer>
    </>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionHeroSearch.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  isInsideContainer: false,
  options: null,
};

SectionHeroSearch.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  isInsideContainer: bool,
  options: propTypeOption,
  
};

export default SectionHeroSearch;
